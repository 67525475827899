/**
 * Breakpoints
 */
export const BREAKPOINTS = {
  mobile: 320,
  tablet: 640,
  desktop: 1024,
  desktopWide: 1440,
} as const;

export type LayoutBreakpoint = keyof typeof BREAKPOINTS;

/**
 * Breakpoint Padding
 * Defines how much padding is on each side of the container
 */
export const BREAKPOINT_PADDING = {
  mobile: 30,
  tablet: 64,
  desktop: 85,
  desktopWide: 120,
} as const;

/* z-index */
export const Z_INDEX = {
  ANNOUNCEMENT_BANNER: 199,
  PRIMARY_NAV: 200,
} as const;
