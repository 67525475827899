export const ATTRIBUTION_COOKIE_NAME = 'f_attr';
export const ATTRIBUTION_COOKIE_KEYS = {
  utmCampaign: 'utm_campaign',
  utmContent: 'utm_content',
  utmMedium: 'utm_medium',
  utmSource: 'utm_source',
  utmTerm: 'utm_term',
  gclid: 'gclid',
  googleClientId: 'google_client_id',
  referrer: 'referrer',
} as const;

export const AFFILIATE_COOKIE_NAME = 'f_aff';
export const AFFILIATE_COOKIE_KEY = 'affiliate';

export const DEBUGGER_COOKIE_NAME = 'front_debug';

export const HUBSPOT_HUTK_COOKIE_NAME = 'hubspotutk';
