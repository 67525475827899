export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) return false;
  const testDummy: TValue = value;

  return true;
}

export function getSingle<EntryType>(entry: EntryType[] | null | undefined): EntryType | null {
  if (!entry || entry.length === 0 || entry == null || entry[0] === null) return null;

  return entry[0];
}

export function maybeNotSet(input: string | null | undefined): string {
  return input || '(not set)';
}
