import { kebabCase } from 'lodash';
import { FC, PropsWithChildren, ReactNode } from 'react';

interface ConditionalWrapperProps extends PropsWithChildren {
  condition: boolean;
  wrapper: (children: ReactNode) => JSX.Element;
}

export const ConditionalWrapper: FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export const copyToClipboard = (text: string) => {
  if (!text) return;

  if (!navigator.clipboard) {
    copyToClipboardFallback(text);

    return;
  }

  navigator.clipboard.writeText(text);
};

const copyToClipboardFallback = (text: string) => {
  const textArea = document.createElement('textarea');

  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const generateRandomID = (length: number = 10) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  let result = '';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const isBrowser = () => typeof window !== 'undefined';

export const addElementIdentifier = (key: string) => ({
  'data-element-identifier': kebabCase(key),
});

interface AttemptOptions {
  maxAttempts: number;
  delay?: number | ((attempt: number) => number);
  initialDelay?: number;
}

export const poll = <T,>(callback: () => Promise<T>, options: AttemptOptions) =>
  new Promise<T>((res, rej) => {
    let attempts = 1;

    const runCallback = () => {
      callback()
        .then(res)
        .catch(() => {
          if (attempts < options.maxAttempts) {
            attempts++;

            const timeout = (typeof options.delay === 'function' ? options.delay(attempts) : options.delay) ?? 0;

            setTimeout(runCallback, timeout);
          } else {
            rej('Max attempts exceeded');
          }
        });
    };

    setTimeout(runCallback, options.initialDelay ?? 0);
  });
