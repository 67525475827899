import gtm from '@/lib/gtm';
import { maybeNotSet } from '@/lib/typescript-helpers';
import Script from 'next/script';
import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

export const NavatticInitScript = (
  // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
  <Script
    id="havattic-init"
    strategy="beforeInteractive"
    dangerouslySetInnerHTML={{
      __html: `
        void 0===window.navatticQueue&&(window.navatticQueue=[],window.navattic=new Proxy({},{get:function n(t,u,e){return function(){for(var n=arguments.length,t=Array(n),e=0;e<n;e++)t[e]=arguments[e];return navatticQueue.push({function:u,arguments:t})}}}));
      `,
    }}
  />
);

export const NavatticScript = (
  <Script src="https://js.navattic.com/embed-events.js" strategy="lazyOnload" key="navattic" />
);

type NavatticMessage = {
  data: {
    kind: 'navattic:event' | 'navattic:ready' | string;
    type: 'VIEW_STEP' | 'NAVIGATE' | 'START_FLOW' | string;
    flow: {
      id: string;
      name: string;
    };
    project: {
      id: string;
      name: string;
    };
    step: {
      id: string;
      indx: number;
    };
    url?: string;
  };
};

interface NavatticIframeProps extends PropsWithClassName {
  src: string;
  onEvent?: (message: NavatticMessage['data']) => void;
}

export const NavatticIframe: FC<NavatticIframeProps> = ({ className, src, onEvent }) => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const handler = (message: NavatticMessage) => {
      if (message.data.kind === 'navattic:event') {
        if (onEvent) onEvent(message.data);

        const eventProps = {
          navattic_project_name: message.data.project.name,
          navattic_flow_name: message.data.flow.name,
          navattic_step: String(message.data.step.indx + 1),
        };

        if (message.data.type === 'START_FLOW') {
          gtm.event('navattic_event_start_flow', {
            ...eventProps,
          });

          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
        }

        if (message.data.type === 'VIEW_STEP') {
          gtm.event('navattic_event_view_step', {
            ...eventProps,
          });

          gtm.track('viewed_tour_step', {
            location: 'Navattic',
            ...eventProps,
          });
        }

        if (message.data.type === 'NAVIGATE') {
          gtm.event('navattic_event_navigate', {
            ...eventProps,
            navattic_navigate_url: maybeNotSet(message.data.url),
          });

          gtm.track('click', {
            location: 'Navattic',
            element_type: 'button',
            element_label: maybeNotSet(message.data.url),
            ...eventProps,
          });
        }
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, [onEvent]);

  return (
    <NavatticWrapper className={className} data-navattic-wrapper>
      {showLoader && (
        <ProgressBar>
          <div></div>
        </ProgressBar>
      )}
      <iframe src={src} allow="fullscreen" />
      {NavatticScript}
    </NavatticWrapper>
  );
};

const NavatticWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 100%;

  iframe {
    z-index: 2;
    position: relative;
    border: none;
    width: 100%;
    height: 100%;
  }
`;

const ProgressBarKeyframes = keyframes`
  from {
    transform: translateX(-200px);
  }

  to {
    transform: translateX(200px);
  }
`;

const ProgressBar = styled.div`
  width: 200px;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #ffe8f0;
  /* Fixes rounded corner overflow in Safari. */
  will-change: transform;
  margin: 0px auto 40px;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);

  > div {
    height: 10px;
    width: 600px;
    margin: 0 -200px;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    background-color: #ffe8f0;
    background: radial-gradient(circle, #ff4c89 0px, #ffe8f0 100px);

    animation-name: ${ProgressBarKeyframes};
    animation-duration: 4s;
  }
`;
