import { customTextSize } from '@/styles/typography/utils';
import { css } from 'styled-components';

export const FONT_FAMILIES = {
  SANS_SERIF: "'Roobert', Helvetica, Arial, sans-serif",
  SERIF: "'Quincy CF', Georgia, 'Times New Roman', Times, serif",
} as const;

export const FONT_WEIGHTS = {
  THIN: 100,
  LIGHT: 300,
  NORMAL: 400,
  MEDIUM: 500,
  SEMIBOLD: 600,
  BOLD: 700,
} as const;

export const TEXT_SIZES = {
  80: css`
    ${customTextSize(80, 96)};
    letter-spacing: -0.01em;
  `,
  68: css`
    ${customTextSize(68, 82)};
    letter-spacing: -0.01em;
  `,
  49: css`
    ${customTextSize(49, 59)};
    letter-spacing: -0.01em;
  `,
  38: css`
    ${customTextSize(38, 46)};
    letter-spacing: -0.01em;
  `,
  32: css`
    ${customTextSize(32, 38)};
    letter-spacing: -0.01em;
  `,
  24: css`
    ${customTextSize(24, 29)};
  `,
  20: css`
    ${customTextSize(20, 28)};
  `,
  18: css`
    ${customTextSize(18, 22)};
  `,
  16: css`
    ${customTextSize(16, 19)};
  `,
  14: css`
    ${customTextSize(14, 17)};
  `,
  13: css`
    ${customTextSize(13, 16)};
  `,
  12: css`
    ${customTextSize(12, 14)};
  `,
} as const;
