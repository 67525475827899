import { DEBUGGER_COOKIE_NAME } from '@/constants/cookies';
import { isBrowser } from '@/lib/utils';
import consola, { ConsolaReporter } from 'consola';
import envServer from 'env.server';
import Cookies from 'js-cookie';

const logger = consola.create({});

if (isBrowser()) {
  const host = window.location.host;

  /* Not using our own cookie lib to avoid circular dependency */
  if (envServer.isProduction && !host.endsWith('.frontpreview.com') && Cookies.get(DEBUGGER_COOKIE_NAME) !== 'true') {
    // @ts-ignore - _reporters is not typed
    logger.removeReporter((logger._reporters as ConsolaReporter[])[0]);
  }
}

export const createLogger = (name: string) => logger.withTag(name.toLowerCase());

export function addReporter(reporter: ConsolaReporter) {
  logger.addReporter(reporter);
}
