import { COLORS } from '.';

export const hexToRGB = (hex: string) => {
  const { r, g, b } = __hexToRGB(hex);

  return `rgb(${r},${g},${b})`;
};

export const hexToRGBA = (hex: string, alpha: number | string) => {
  const { r, g, b } = __hexToRGB(hex);

  return `rgba(${r},${g},${b},${alpha})`;
};

export const getBrandColor = (
  color: string | null | undefined,
  shade: 'lowlight' | 'base' | 'highlight' | 'tint' = 'base',
): string | null => {
  if (!color) return null;

  try {
    return COLORS[color.toUpperCase()][shade.toUpperCase()];
  } catch (e) {
    return null;
  }
};

/* Private Methods */

const __hexToRGB = (hex: string) => {
  const hexValue = hex.replace('#', '');

  const r = parseInt(hexValue.length === 3 ? hexValue.slice(0, 1).repeat(2) : hexValue.slice(0, 2), 16);
  const g = parseInt(hexValue.length === 3 ? hexValue.slice(1, 2).repeat(2) : hexValue.slice(2, 4), 16);
  const b = parseInt(hexValue.length === 3 ? hexValue.slice(2, 3).repeat(2) : hexValue.slice(4, 6), 16);

  return { r, g, b };
};
