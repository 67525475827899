import { BASE_FONT_SIZE } from '@/styles/typography/base';

export const remCalc = (...sizes: number[]) =>
  sizes
    .map((size) => {
      if (size === 0) return '0';

      return `${size / BASE_FONT_SIZE}rem`;
    })
    .join(' ');

export const customTextSize = (fontSize: number, lineHeight: number) => ({
  fontSize: remCalc(fontSize),
  lineHeight: remCalc(lineHeight),
});
