'use client';

import { FC, SVGProps } from 'react';

const IconGridBreakup: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width="1511" height="307" viewBox="0 0 1511 307" {...props}>
      <rect x="1511" y="306.383" width="190.25" height="129.282" transform="rotate(-180 1511 306.383)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="1320.75" y="177.104" width="190.25" height="38.571" transform="rotate(-180 1320.75 177.104)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="1320.75" y="306.383" width="190.25" height="107.45" transform="rotate(-180 1320.75 306.383)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="1511" y="157.819" width="190.25" height="42.902" transform="rotate(-180 1511 157.819)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="1320.75" y="114.918" width="190.25" height="25.1885" transform="rotate(-180 1320.75 114.918)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="1130.5" y="140.106" width="190.25" height="25.1885" transform="rotate(-180 1130.5 140.106)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="750" y="140.106" width="190.25" height="25.1885" transform="rotate(-180 750 140.106)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="369.5" y="118.854" width="190.25" height="40.1387" transform="rotate(-180 369.5 118.854)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="369.5" y="195.788" width="190.25" height="40.1387" transform="rotate(-180 369.5 195.788)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="369.5" y="306.383" width="190.25" height="100.843" transform="rotate(-180 369.5 306.383)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="179.25" y="306.383" width="190.25" height="118.662" transform="rotate(-180 179.25 306.383)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="179.25" y="166.659" width="190.25" height="69.2685" transform="rotate(-180 179.25 166.659)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="179.25" y="81.4553" width="190.25" height="24.79" transform="rotate(-180 179.25 81.4553)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="179.25" y="17.6958" width="190.25" height="17.6957" transform="rotate(-180 179.25 17.6958)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="369.5" y="46.8284" width="190.25" height="20.0693" transform="rotate(-180 369.5 46.8284)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="750" y="214.096" width="190.25" height="49.5888" transform="rotate(-180 750 214.096)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="750" y="306.383" width="190.25" height="60.9221" transform="rotate(-180 750 306.383)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="559.75" y="306.383" width="190.25" height="69.8619" transform="rotate(-180 559.75 306.383)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="559.75" y="225.309" width="190.25" height="49.5888" transform="rotate(-180 559.75 225.309)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="559.75" y="145.825" width="190.25" height="11.434" transform="rotate(-180 559.75 145.825)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="1130.5" y="214.096" width="190.25" height="48.8006" transform="rotate(-180 1130.5 214.096)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="1130.5" y="306.383" width="190.25" height="81.0745" transform="rotate(-180 1130.5 306.383)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="940.25" y="306.383" width="190.25" height="60.9221" transform="rotate(-180 940.25 306.383)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="940.25" y="236.518" width="190.25" height="48.8006" transform="rotate(-180 940.25 236.518)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="940.25" y="166.659" width="190.25" height="26.5525" transform="rotate(-180 940.25 166.659)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="1511" y="89.7271" width="190.25" height="25.1885" transform="rotate(-180 1511 89.7271)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
      <rect x="1511" y="39.356" width="190.25" height="12.5943" transform="rotate(-180 1511 39.356)" fill="currentColor" stroke="currentColor" strokeWidth={0.5} />
    </svg>

  );
};

export default IconGridBreakup;
