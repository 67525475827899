import { BREAKPOINTS, LayoutBreakpoint } from '@/styles/layout';
import { remCalc } from '@/styles/typography/utils';

export const square = (size: number) => ({
  width: remCalc(size),
  height: remCalc(size),
});

export const paddingX = (l: number, r?: number) => ({
  paddingLeft: remCalc(l),
  paddingRight: remCalc(r ?? l),
});

export const paddingY = (t: number, b?: number) => ({
  paddingTop: remCalc(t),
  paddingBottom: remCalc(b ?? t),
});

export const marginX = (l: number | 'auto', r?: number | 'auto') => {
  const marginLeft = l === 'auto' ? l : remCalc(l);
  const marginRight = r === undefined ? marginLeft : r === 'auto' ? r : remCalc(r);

  return {
    marginLeft,
    marginRight,
  };
};

export const marginY = (t: number, b?: number) => ({
  marginTop: remCalc(t),
  marginBottom: remCalc(b ?? t),
});

export const BREAKPOINT_VAR = 'var(--breakpoint-x)';
export const BREAKPOINT_PADDING_VAR = 'var(--breakpoint-padding-x)';

export const applyBreakpointPadding = {
  paddingLeft: BREAKPOINT_PADDING_VAR,
  paddingRight: BREAKPOINT_PADDING_VAR,
};

export const applyBreakpointNegativeMargins = {
  marginLeft: `calc(${BREAKPOINT_PADDING_VAR} * -1)`,
  marginRight: `calc(${BREAKPOINT_PADDING_VAR} * -1)`,
};

export const mediaBreakpointUp = (b: LayoutBreakpoint) => `@media (min-width: ${remCalc(BREAKPOINTS[b])})`;

export const mediaBreakpointDown = (b: LayoutBreakpoint) => {
  let pixels = 0;

  const index = Object.keys(BREAKPOINTS).findIndex((breakpoint) => breakpoint === b);
  const nextBreakpoint = Object.values(BREAKPOINTS)[index + 1];

  if (nextBreakpoint) {
    pixels = nextBreakpoint - 1;
  }

  return `@media (max-width: ${remCalc(pixels)})`;
};

export const mediaBreakpointBetween = (b1: LayoutBreakpoint, b2: LayoutBreakpoint) => {
  const sorted = [BREAKPOINTS[b1], BREAKPOINTS[b2]].sort((a, b) => a - b);

  return `@media (min-width: ${remCalc(sorted[0])}) and (max-width: ${remCalc(sorted[1])})`;
};

export const mediaBreakpointOnly = (b: LayoutBreakpoint) => {
  let min = 0;
  let max = 0;

  const index = Object.keys(BREAKPOINTS).findIndex((breakpoint) => breakpoint === b);

  if (index === 0) {
    max = Object.values(BREAKPOINTS)[1] - 1;
  } else {
    min = Object.values(BREAKPOINTS)[index];

    const nextBreakpoint = Object.values(BREAKPOINTS)[index + 1];

    if (nextBreakpoint) {
      max = nextBreakpoint - 1;
    }
  }

  if (max > 0) {
    return `@media (min-width: ${remCalc(min)}) and (max-width: ${remCalc(max)})`;
  }

  return `@media (min-width: ${remCalc(BREAKPOINTS[b])})`;
};
