// prettier-ignore
module.exports = {
  automationBypass:             process.env.VERCEL_AUTOMATION_BYPASS_SECRET,
  clearbitSecretKey:            process.env.CLEARBIT_SECRET_KEY,
  cloudflareAccessClientId:     process.env.CLOUDFLARE_ACCESS_CLIENT_ID,
  cloudflareAccessClientSecret: process.env.CLOUDFLARE_ACCESS_CLIENT_SECRET,
  cloudfrontUrl:                process.env.CLOUDFRONT_URL,
  craftPreviewSecurityToken:    process.env.CRAFT_PREVIEW_TOKEN,
  disableOptimizely:            (process.env.DISABLE_OPTIMIZELY ?? '').toLowerCase() === 'true',
  frontAppDomain:               process.env.FRONT_APP_DOMAIN,
  graphqlSchemaVersion:         parseInt(process.env.GRAPHQL_SCHEMA_VERSION, 10),
  graphqlToken:                 process.env.GRAPHQL_TOKEN,
  graphqlUrl:                   process.env.GRAPHQL_URL,
  hubspotApiToken:              process.env.HUBSPOT_API_TOKEN,
  isProduction:                 process.env.NODE_ENV === 'production',
  isVercel:                     process.env.VERCEL === '1',
  linkedinApiToken:             process.env.LINKEDIN_API_TOKEN,
  port:                         process.env.PORT ?? 3000,
  skipPagePaths:                process.env.SKIP_PAGE_PATHS === '1',
  vercelBuilding:               process.env.CI === '1',
  vercelEdgeConfig:             process.env.EDGE_CONFIG,
  vercelEdgeConfigToken:        process.env.EDGE_CONFIG_API_TOKEN,
  vercelEnv:                    process.env.VERCEL_ENV,
  vercelTeamId:                 process.env.VERCEL_TEAM_ID,
};
